import React from "react"
import ProductPage from "../../layouts/product-page"

const model = {
  eng: {
    id: "7wifi",
    model: "P13  7’’ WiFi",
    os: "Android Oreo (Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "N/A",
    display: "7.0“,TN,1024*600 (Landscape)，Capacitive multi-touch",
    memory: "1GB+16GB (Compatible to 1GB+16GB)",
    dimensions: "189.5x 111 x 9.15mm, 245.2g",
    cameras:
      "front: VGA FF (SW to 2M FF, Compatible with 2M FF | rear: 2MP FF (SW to 5M FF, Compatible with 5M FF)",
    connectivity:
      "WiFi 802.11 b/g/n, BT4.2, FM(use headset for antenna), GPS(default for LATAM)",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack , TF Card Slot, OTG",
    battery:
      "2580mAh(Min.), Non-removable Up to 7.3 hours (mixed-use battery life)",
    cmf: "Raw Material",
    accessories:
      "default:	Charger 5V/1A, USB cable, QG, LCD Sticker | optional: Headset, Stand flip case, Bumper case, Stylus pen",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3cYih9X6ar-s2i7sCW9HSOlCfji2epqgal4DjUhCA_9MuVXkwneMCB_2w51iwcCosh2dWpWY_ObPGRYsY7nQfB6FKj4rSfeDaRYrArRVCrHmtHG9I1pcwSvz8JRYtU7Pp8zr4_2gHfvLjCZqw6CUUE=d",
  },
  esp: {
    id: "7wifi",
    model: "P13  7’’ WiFi",
    os: "Android Oreo (Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "N/A",
    display: "7.0“,TN,1024*600 (Landscape)，Capacitive multi-touch",
    memory: "1GB+16GB (Compatible to 1GB+16GB)",
    dimensions: "189.5x 111 x 9.15mm, 245.2g",
    cameras:
      "front: VGA FF (SW to 2M FF, Compatible with 2M FF | rear: 2MP FF (SW to 5M FF, Compatible with 5M FF)",
    connectivity:
      "WiFi 802.11 b/g/n, BT4.2, FM(use headset for antenna), GPS(default for LATAM)",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack , TF Card Slot, OTG",
    battery:
      "2580mAh(Min.), Non-removable Up to 7.3 hours (mixed-use battery life)",
    cmf: "Raw Material",
    accessories:
      "default:	Charger 5V/1A, USB cable, QG, LCD Sticker | optional: Headset, Stand flip case, Bumper case, Stylus pen",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3cYih9X6ar-s2i7sCW9HSOlCfji2epqgal4DjUhCA_9MuVXkwneMCB_2w51iwcCosh2dWpWY_ObPGRYsY7nQfB6FKj4rSfeDaRYrArRVCrHmtHG9I1pcwSvz8JRYtU7Pp8zr4_2gHfvLjCZqw6CUUE=d",
  },
}
export default () => <ProductPage model={model} />
